import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import ProjectsPage from "./components/ProjectsPage";
import TransitionToTop from "./components/common/transitionToTop";
import PricingPage from "./components/PricingPage";
import FeaturesPage from "./components/FeaturesPage";
import ServicesPage from "./components/ServicesPage";
import AboutPage from "./components/AboutPage";
import { Toaster } from "react-hot-toast";
import Subscription from "./components/common/Subscription";

function App() {
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/subscription" element={<Subscription />} />
        </Routes>
        <TransitionToTop />
      </Router>
    </>
  );
}

export default App;
