import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import toast from "react-hot-toast";
import axios from "axios";
import { server } from "../../server";
import { FaCopy } from "react-icons/fa";

const Subscription = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [mpesaLoading, setMpesaLoading] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy!");
      });
  };

  const ToastWithCopy = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex" }}>
        Our email:{" "}
        <span style={{ color: "green", marginLeft: "4px" }}>{message}</span>
      </span>
      <FaCopy
        style={{ marginLeft: "8px", cursor: "pointer", color: "green" }}
        onClick={() => copyToClipboard(message)}
      />
    </div>
  );

  const sendGitHubInvite = async (username) => {
    try {
      const response = await axios.post(`${server}/sendallinvitation`, {
        username: username,
      });

      if (response.status === 200) {
        toast.success("Invitation was sent to your Email", {
          duration: 30000,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(`Error: ${error.response.data.message}`);
        toast.custom(
          (a) => (
            <div
              style={{
                maxWidth: "30rem",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                pointerEvents: "auto",
                display: "block",
                flexDirection: "row",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                opacity: a.visible ? 1 : 0,
                transition: "opacity 0.3s ease",
              }}
            >
              <div style={{ flex: 1, padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#1F2937",
                      }}
                    >
                      {" "}
                      We did not find a github username you provided.
                    </p>
                    <p
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "13px",
                        color: "#6B7280",
                      }}
                    >
                      {" "}
                      Please email{" "}
                      <ToastWithCopy message={"info@threedolts.co.ke"} /> and we
                      will send you the link direct to your email.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  borderTop: "1px solid rgba(229, 229, 229, 1)",
                }}
              >
                <button
                  onClick={() => {
                    toast.dismiss(a.id);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0",
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "#6366F1",
                    cursor: "pointer",
                    transition: "color 0.2s ease",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "#4F46E5")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "#6366F1")}
                >
                  Close
                </button>
              </div>
            </div>
          ),
          { duration: 30000 }
        );
      } else {
        toast.error("An error occurred while sending the GitHub invite.");
        toast.custom(
          (a) => (
            <div
              style={{
                maxWidth: "30rem",
                width: "100%",
                backgroundColor: "white",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                borderRadius: "0.5rem",
                pointerEvents: "auto",
                display: "block",
                flexDirection: "row",
                border: "1px solid rgba(0, 0, 0, 0.1)",
                opacity: a.visible ? 1 : 0,
                transition: "opacity 0.3s ease",
              }}
            >
              <div style={{ flex: 1, padding: "1rem" }}>
                <div style={{ display: "flex", alignItems: "start" }}>
                  <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                    <p
                      style={{
                        fontSize: "13px",
                        fontWeight: "500",
                        color: "#1F2937",
                      }}
                    >
                      {" "}
                      We did not find a github username you provided.
                    </p>
                    <p
                      style={{
                        marginTop: "0.25rem",
                        fontSize: "13px",
                        color: "#6B7280",
                      }}
                    >
                      {" "}
                      Please email{" "}
                      <ToastWithCopy message={"info@threedolts.co.ke"} /> and we
                      will send you the link direct to your email.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "block",
                  borderTop: "1px solid rgba(229, 229, 229, 1)",
                }}
              >
                <button
                  onClick={() => {
                    toast.dismiss(a.id);
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0",
                    padding: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                    fontWeight: "500",
                    color: "#6366F1",
                    cursor: "pointer",
                    transition: "color 0.2s ease",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                  onMouseOver={(e) => (e.currentTarget.style.color = "#4F46E5")}
                  onMouseOut={(e) => (e.currentTarget.style.color = "#6366F1")}
                >
                  Close
                </button>
              </div>
            </div>
          ),
          { duration: 30000 }
        );
      }
    }
  };

  const payheroHandler = async (phone, price, username) => {
    setMpesaLoading(true);
    const toastId = toast.loading("Loading...");
    try {
      await axios
        .post(`${server}/payhero-stk`, {
          amount: Math.floor(price),
          phone: phone,
        })
        .then(async (res) => {
          toast.dismiss(toastId);
          toast.success("stk pushed");
          toast.custom(
            (t) => (
              <div
                style={{
                  maxWidth: "30rem",
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "0.5rem",
                  pointerEvents: "auto",
                  display: "block",
                  flexDirection: "row",
                  opacity: t.visible ? 1 : 0,
                  transition: "opacity 0.3s ease",
                  border: "1px solid rgba(0, 0, 0, 0.05)",
                }}
              >
                <div
                  style={{ width: "100%", padding: "1rem", flex: "1 1 auto" }}
                >
                  <div style={{ display: "flex", alignItems: "start" }}>
                    <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                      <p
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          color: "#1F2937",
                        }}
                      >
                        We Sent you STK push to your Phone
                      </p>
                      <p
                        style={{
                          marginTop: "0.25rem",
                          fontSize: "13px",
                          color: "#6B7280",
                        }}
                      >
                        Please enter your PIN to complete payments
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    borderTop: "1px solid rgba(229, 229, 229, 1)",
                  }}
                >
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    style={{
                      width: "100%",
                      padding: "1rem",
                      fontSize: "13px",
                      fontWeight: "500",
                      color: "#6366F1",
                      cursor: "pointer",
                      transition: "color 0.2s ease",
                      borderTop: "1px solid rgba(229, 229, 229, 1)",
                      borderRight: "1px solid rgba(229, 229, 229, 1)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "#4F46E5")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#6366F1")
                    }
                  >
                    Close
                  </button>
                </div>
              </div>
            ),
            { duration: 30000 }
          );

          const checkPaymentStatus = async (res) => {
            const toastId = toast.loading("Verifying Payment....");
            setTimeout(async () => {
              try {
                const resp = await axios.get(
                  `${server}/payhero-check-status/${res.data.data.reference}`,
                  null
                );
                setMpesaLoading(false);

                if (resp.data.data.status === "SUCCESS") {
                  toast.dismiss(toastId);
                  setModalVisible(false);
                  await sendGitHubInvite(username);
                  toast.success("Payment Successful. Thanks Alot.");
                } else {
                  toast.dismiss(toastId);
                  toast.error("Payments was not confirmed");
                  toast.custom(
                    (a) => (
                      <div
                        style={{
                          maxWidth: "30rem",
                          width: "100%",
                          backgroundColor: "white",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          borderRadius: "0.5rem",
                          pointerEvents: "auto",
                          display: "block",
                          flexDirection: "row",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                          opacity: a.visible ? 1 : 0,
                          transition: "opacity 0.3s ease",
                        }}
                      >
                        <div style={{ flex: 1, padding: "1rem" }}>
                          <div style={{ display: "flex", alignItems: "start" }}>
                            <div style={{ marginLeft: "0.75rem", flex: 1 }}>
                              <p
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "500",
                                  color: "#1F2937",
                                }}
                              >
                                {" "}
                                We Could not Verify Your Payment.
                              </p>
                              <p
                                style={{
                                  marginTop: "0.25rem",
                                  fontSize: "13px",
                                  color: "#6B7280",
                                }}
                              >
                                {" "}
                                If Payments was deducted from your account,
                                click Re-Confirm.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "block",
                            borderTop: "1px solid rgba(229, 229, 229, 1)",
                          }}
                        >
                          {" "}
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                              checkPaymentStatus(resp);
                            }}
                            style={{
                              width: "100%",
                              borderRight: "1px solid rgba(74, 222, 128, 1)",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Re-Confirm
                          </button>
                          <button
                            onClick={() => {
                              toast.dismiss(a.id);
                            }}
                            style={{
                              width: "100%",
                              borderRadius: "0",
                              padding: "1rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "13px",
                              fontWeight: "500",
                              color: "#6366F1",
                              cursor: "pointer",
                              transition: "color 0.2s ease",
                              borderTop: "1px solid rgba(229, 229, 229, 1)",
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.style.color = "#4F46E5")
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.style.color = "#6366F1")
                            }
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ),
                    { duration: 30000 }
                  );
                }
              } catch (error) {
                toast.dismiss(toastId);
                setMpesaLoading(false);
                toast.error("Error Occurred, Please try Again!");
                console.log(error);
              }
            }, 30000);
          };
          checkPaymentStatus(res);
        })
        .catch((error) => {
          setMpesaLoading(false);
          toast.dismiss(toastId);
          toast.error("Error Ocurred, Please try Again Later.");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const modalData = {
    text1: `Pay sh. 20,000.`,
    text2: "Please provide your Details.",
    mpesaLoading: mpesaLoading,
    btn1Text: "Submit",
    btn2Text: "Cancel",
    btn1Handler: (phoneNumber, username) => {
      const phoneRegex = /^\d{10}$/;

      if (username && phoneNumber && phoneRegex.test(phoneNumber)) {
        payheroHandler(phoneNumber, 20000, username);
      } else if (!phoneRegex.test(phoneNumber)) {
        toast.error("Oops! Please enter a valid 10-digit phone number.", {
          duration: 10000,
        });
      } else {
        toast.error(
          "Oops! Please enter an Github username and a 10-digit phone number.",
          {
            duration: 10000,
          }
        );
      }
    },

    btn2Handler: () => {
      toast.success("No worries! We're here whenever you're ready 😊💖");
      setModalVisible(false);
      setMpesaLoading(false);
    },
  };

  const openModal = () => {
    setModalVisible(true);
  };
  return (
    <>
      <Header color="#51558a" />
      {modalVisible && <Modal modalData={modalData} />}

      <div
        style={{
          marginTop: "70px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#0f172a",
          padding: "50px 0",
        }}
      >
        <div
          style={{
            width: "360px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0 10px 30px rgba(0, 0, 0, 0.15)",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <h2
            style={{
              fontSize: "22px",
              fontWeight: "600",
              color: "#0f172a",
              marginBottom: "10px",
            }}
          >
            Lifetime access
          </h2>

          <div style={{ margin: "15px 0", color: "#1e293b" }}>
            <span style={{ fontSize: "30px", fontWeight: "700" }}>
              sh. 20,000
            </span>
            <span
              style={{
                textDecoration: "line-through",
                fontSize: "18px",
                color: "#64748b",
                marginLeft: "10px",
              }}
            >
              sh. 30,000
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f1f5f9",
              padding: "12px 15px",
              borderRadius: "8px",
              marginBottom: "20px",
            }}
          >
            <span style={{ fontSize: "14px", color: "#1e293b" }}>
              Get <strong>30% discount</strong> for Kenyans
            </span>
          </div>

          {/* Features List */}
          <ul
            style={{
              listStyle: "none",
              padding: 0,
              textAlign: "left",
              margin: "20px 0",
            }}
          >
            {[
              "Full access to all courses",
              "Unlock all source code",
              "Full support in Emails",
              "Over 20 premium courses",
              "Over 10 workshops",
              "New courses every month",
              "One-time payment",
            ].map((item, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                  color: "#1e293b",
                  fontSize: "14px",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#0ea5e9"
                  style={{ width: "18px", height: "18px", marginRight: "10px" }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                {item}
              </li>
            ))}
          </ul>

          <button
            style={{
              backgroundColor: "#0ea5e9",
              color: "#ffffff",
              padding: "12px 20px",
              border: "none",
              borderRadius: "6px",
              cursor: "pointer",
              fontSize: "16px",
              fontWeight: "600",
              width: "100%",
              transition: "background-color 0.3s",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#0284c7")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#0ea5e9")}
            onClick={() => openModal()}
          >
            Buy now
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Subscription;
