import project1 from "../images/ninetyone.png";
import project2 from "../images/ninetyone2.png";
import project3 from "../images/threedolts.png";
import project4 from "../images/bani.png";
import project5 from "../images/spices.png";
import project6 from "../images/salvian.png";
import project7 from "../images/cgym.png";
import project8 from "../images/hybridmotor.png";
import project9 from "../images/movies-web.png";
import project10 from "../images/threed-edu.png";
import project12 from "../images/nonex.png";
import project13 from "../images/lenders.png";
import project27 from "../images/pafrim.png";
import project77 from "../images/insurance.png";

const projects = [
  {
    image: project1,
    name: "Ninetyone - ecommerce website",
    desc: "Best Selling Multi Vendor ecommerce Website. Empower Your Business with the Top-Performing Multi-Vendor E-commerce Platform",
    date: "21 Dec 2020",
    link: "https://www.ninetyone.co.ke/",
    likes: 14,
  },
  {
    image: project2,
    name: "Ninetyone subsidiary Website",
    desc: "Single Vender E-commerce business, a subsidiary of ninetyone.co.ke.",
    date: "26 Dec 2020",
    link: "https://ninetyone.vercel.app/",
    likes: 22,
  },
  {
    image: project10,
    name: "Three Dolts Education- LMS",
    desc: "Empower Your Future with Valuable Skills",
    date: "30 Sebtember 2024",
    link: "https://threed-edu.vercel.app",
  },
  {
    image: project12,
    name: "Nonex Computers Website - Thika ",
    desc: " With a commitment to seamless user experiences and cutting-edge technologies, I specialize in turning ideas into captivating realities.",
    date: "15 Feb 2021",
    link: "https://nonexcomputers.com/",
    likes: 19,
  },
  {
    image: project13,
    name: "Lenders Bay Credit Website",
    desc: "This is a loan management stystem website with fully fuctional admin dashboard and a users page. It manages loans from the day of release to maturity.",
    date: "25 Feb 2021",
    link: "https://lendersbaycredit.com/",
    likes: 6,
  },
  {
    image: project27,
    name: "Land Agent Website",
    desc: "Your satisfaction is our top priority. We actively seek your feedback to continuously improve and ensure a fair and transparent experience.",
    date: "11 July 2022",
    link: "https://pafriminvestments.com/",
    likes: 15,
  },
  {
    image: project3,
    name: "3 Dolts Websites",
    desc: "We Design Interfaces That Users Love",
    date: "10 July 2020",
    link: "https://threedolts.co.ke/",
    likes: 15,
  },
  {
    image: project4,
    name: "Bani Logistics Websites",
    desc: "We're Global Logistics Partners",
    date: "In Progress",
    link: "https://www.banilogistics.co.ke/",
    likes: 15,
  },
  {
    image: project77,
    name: "Insurance Co Website",
    desc: "Life insurance makes your life happier",
    date: "20 July 2024",
    link: "https://insurance-hazel.vercel.app/",
  },
  {
    image: project5,
    name: "Lugi Spices Website",
    desc: "We don't just spice, we create your emotions!",
    date: "05 Aug 2024",
    link: "https://www.lunghispices.co.ke",
  },
  {
    image: project6,
    name: "Salvian Energies Websites",
    desc: "Delivering top-quality petroleum with unmatched reliability to keep your operations running smoothly.",
    date: "17 August 2024",
    link: "https://www.salvianenergies.com",
  },
  {
    image: project7,
    name: "Fitlife Gym Websites",
    desc: "Work Hard To Get Better Life Welcome To Our Fitness Gym",
    date: "27 August 2024",
    link: "https://fitlife-gym.vercel.app",
  },
  {
    image: project8,
    name: "Hybrid Motors Solution",
    desc: "Auto Maintenance & Repair Service. Our mission to provide top-notch automotive repair and maintenance services with integrity and professionalism.",
    date: "30 September 2024",
    link: "https://hybridmotorssolutions.co.ke",
  },
  {
    image: project9,
    name: "Movies Website",
    desc: "Welcome. Millions of movies, TV shows and people to discover. Explore now.",
    date: "27 Sebtember 2024",
    link: "https://movies-website-sooty.vercel.app",
  },
];
export default projects;
