import React, { useState } from "react";

export default function Modal({ modalData }) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [username, setuserName] = useState("");

  const handlePhoneChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleUserNameChange = (event) => {
    setuserName(event.target.value);
  };

  // Handle submit button click
  const handleSubmit = () => {
    if (modalData?.btn1Handler) {
      modalData.btn1Handler(phoneNumber, username);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        zIndex: 1000, 
        display: "grid", 
        placeItems: "center",
        overflow: "auto",
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        backdropFilter: "blur(5px)",
      }}
    >
      <div
        style={{
          width: "90%",
          maxWidth: "350px",
          borderRadius: "8px",
          border: "1px solid #4A4A4A",
          backgroundColor: "#2C2C2C",
          padding: "24px",
        }}
      >
        <p
          style={{
            fontSize: "1.4rem",
            fontWeight: "600",
            color: "#D1D1D1",
          }}
        >
          {modalData?.text1}
        </p>
        <p
          style={{
            marginTop: "12px",
            marginBottom: "20px",
            color: "#fff",
            lineHeight: "1.6",
          }}
        >
          {modalData?.text2}
        </p>

        {/* Phone Number Input */}
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="email"
            style={{
              display: "flex",
              color: "#D1D1D1",
              fontSize: "1.2rem",
              marginBottom: "6px",
            }}
          >
            Phone*{" "}
            <span style={{ fontSize: "11px", margin: "2px 0 0 2px" }}>
              (We will send the stk push here.)
            </span>
          </label>{" "}
          <input
            type="tel"
            placeholder="Enter phone number"
            value={phoneNumber}
            onChange={handlePhoneChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #4A4A4A",
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1.4rem",
              marginBottom: "12px",
            }}
          />
        </div>

        {/* Email Input */}

        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="email"
            style={{
              display: "flex",
              color: "#D1D1D1",
              fontSize: "1.2rem",
              marginBottom: "6px",
            }}
          >
            Github UserName*{" "}
            <span style={{ fontSize: "11px", margin: "2px 0 0 2px" }}>
              (We will send the code here.)
            </span>
          </label>{" "}
          <input
            type="email"
            placeholder="Enter your email."
            value={username}
            onChange={handleUserNameChange}
            style={{
              width: "100%",
              padding: "8px",
              borderRadius: "4px",
              border: "1px solid #4A4A4A",
              backgroundColor: "#333",
              color: "#fff",
              fontSize: "1.4rem",
              marginBottom: "12px",
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <button
            onClick={handleSubmit}
            style={{
              cursor: modalData?.mpesaLoading === true ? "wait" : "pointer",
              borderRadius: "4px",
              backgroundColor:
                modalData?.mpesaLoading === true ? "#F3C2C2" : "#FF4C4C",
              padding: "8px 20px",
              fontWeight: "600",
              color: "#fff",
            }}
            disabled={modalData?.mpesaLoading === true}
          >
            {modalData?.mpesaLoading === true
              ? "Submiting ..."
              : modalData?.btn1Text}
          </button>
          <button
            onClick={modalData?.btn2Handler}
            style={{
              cursor: "pointer",
              borderRadius: "4px",
              backgroundColor: "#4A4A4A",
              padding: "8px 20px",
              fontWeight: "600",
              color: "#FFFFFF",
            }}
          >
            {modalData?.btn2Text}
          </button>
        </div>
      </div>
    </div>
  );
}
