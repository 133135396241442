import React from "react";
// import about from "../../images/about-banner.png";
import about from "../../images/about.svg";
import toast from "react-hot-toast";
import { FaCopy } from "react-icons/fa";

const About = () => {
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch(() => {
        toast.error("Failed to copy!");
      });
  };

  const callUs = () => {
    const message = "0791004783";
    toast(<ToastWithCopy message={message} />, { duration: 10000 });
    window.location.href = "tel:0791004783";
  };

  const emailUs = () => {
    const message = "info@threedolts.co.ke";
    toast(<ToastWithCopy message={message} />, { duration: 10000 });
    window.location.href = "mailto:info@threedolts.co.ke";
  };

  const ToastWithCopy = ({ message }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex" }}>
        Copy Me:{" "}
        <span style={{ color: "green", marginLeft: "4px" }}>{message}</span>
      </span>
      <FaCopy
        style={{ marginLeft: "8px", cursor: "pointer", color: "green" }}
        onClick={() => copyToClipboard(message)}
      />
    </div>
  );
  return (
    <>
      {" "}
      <section class="section about" id="about">
        <div class="container">
          <figure class="about-banner">
            <img
              src={about}
              width="700"
              height="532"
              loading="lazy"
              alt="about banner"
              class="w-100 banner-animation"
            />
          </figure>

          <div class="about-content">
            <h2 class="h2 section-title underline">Why Our Agency</h2>

            <p class="about-text">
              Calling Nairobi, Kenya our home, we are a team of Full-Stack
              Website developers , driven by our passion for coding excellence
              and innovation. With backgrounds in Information Technology from
              top Kenyan universities, we thrive on honing our skills and
              crafting stunning applications and websites.
            </p>

            <p class="about-text">
              And yes, we have a soft spot for crafting Full-Stack clones that
              are as impressive as they are functional. Let's embark on this
              coding journey together and make your online presence truly
              remarkable.
            </p>
            <div>
              <div
                className="publish-date"
                style={{
                  color: "#D7006C",
                  backgroundColor: "transparent",
                  padding: "9px 20px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  transition:
                    "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                  border: "1px solid #D7006C",
                  textAlign: "center",
                  display: "inline-block",
                  textDecoration: "none",
                  margin: "5px",
                }}
                onClick={() => callUs()}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.05)";
                  e.target.style.borderColor = "#FF3385";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.borderColor = "#D7006C";
                }}
              >
                <p class="" style={{ display: "flex", alignItems: "center" }}>
                  <ion-icon
                    name="headset-outline"
                    style={{ marginRight: "3px" }}
                  ></ion-icon>{" "}
                  0791 004 783
                </p>
              </div>
              <div
                className="publish-date"
                style={{
                  color: "#D7006C",
                  backgroundColor: "transparent",
                  padding: "9px 20px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  transition:
                    "transform 0.3s, box-shadow 0.3s, border-color 0.3s",
                  border: "1px solid #D7006C",
                  textAlign: "center",
                  display: "inline-block",
                  textDecoration: "none",
                  margin: "5px",
                }}
                onClick={() => emailUs()}
                onMouseEnter={(e) => {
                  e.target.style.transform = "scale(1.05)";
                  e.target.style.borderColor = "#FF3385";
                }}
                onMouseLeave={(e) => {
                  e.target.style.transform = "scale(1)";
                  e.target.style.borderColor = "#D7006C";
                }}
              >
                <p class="" style={{ display: "flex", alignItems: "center" }}>
                  <ion-icon
                    name="mail-outline"
                    style={{ marginRight: "3px" }}
                  ></ion-icon>{" "}
                  info@threedolts.co.ke
                </p>
              </div>
            </div>

            <ul class="stats-list">
              <li class="stats-card">
                <p class="h3 stats-title">85</p>

                <p class="stats-text">Satisfied Clients</p>
              </li>

              <li class="stats-card">
                <p class="h3 stats-title">96</p>

                <p class="stats-text">Project Lunched</p>
              </li>

              <li class="stats-card">
                <p class="h3 stats-title">7</p>

                <p class="stats-text">Years Completed</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
