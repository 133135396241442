import React from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import Hero from "./common/Hero";
import About from "./common/About";
import Services from "./common/Services";
import Features from "./common/Features";
import Pricing from "./common/Pricing";
import Projects from "./common/Projects";

const Home = () => {
  return (
    <div>
      <Header />
      <Hero />
      <Projects />
      <About />
      <Services />
      <Features />
      <Pricing />
      <Footer />
    </div>
  );
};

export default Home;
